import axios from "../../axios-base";

export const clear = () => {
  return function (dispatch, getState) {
    dispatch(clearStart);
    dispatch(loadServiceCategories);
  };
};

const errorMessage = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clearStart = () => {
  return {
    type: "CLEAR_SERVICECATEGORIES",
  };
};

export const loadServiceCategories = () => {
  return function (dispatch, getState) {
    dispatch(loadServiceCategoriesStart());
    axios
      .get("service-categories")
      .then((response) => {
        const result = response.data.data;
        dispatch(loadServiceCategoriesSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadServiceCategoriesError(resultError));
      });
  };
};
export const loadServiceCategoriesStart = () => {
  return {
    type: "LOAD_SERVICE_CATEGORIES_START",
  };
};

export const loadServiceCategoriesSuccess = (result) => {
  return {
    type: "LOAD_SERVICE_CATEGORIES_SUCCESS",
    categories: result,
  };
};

export const loadServiceCategoriesError = (error) => {
  return {
    type: "LOAD_SERVICE_CATEGORIES_ERROR",
    error,
  };
};

// SINGLE CATEGORY

export const loadServiceCategory = (serviceCategoryId) => {
  return function (dispatch, getState) {
    dispatch(loadServiceCategoryStart());
    axios
      .get(`service-categories/${serviceCategoryId}`)
      .then((response) => {
        const loadedServiceCategory = response.data.data;
        dispatch(loadServiceCategorySuccess(loadedServiceCategory));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadServiceCategoryError(resultError));
      });
  };
};

export const loadServiceCategoryStart = () => {
  return {
    type: "LOAD_SERVICE_CATEGORY_START",
  };
};

export const loadServiceCategorySuccess = (result) => {
  return {
    type: "LOAD_SERVICE_CATEGORY_SUCCESS",
    category: result,
  };
};

export const loadServiceCategoryError = (error) => {
  return {
    type: "LOAD_SERVICE_CATEGORY_ERROR",
    error,
  };
};

// Change positions
export const changePosition = (data) => {
  return function (dispatch) {
    dispatch(changePositionStart());

    axios
      .post("service-categories/change", data)
      .then((response) => {
        const result = response.data.data;
        dispatch(changePositionSuccess(result));
        dispatch(loadServiceCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(changePositionError(resultError));
      });
  };
};

export const changePositionStart = (result) => {
  return {
    type: "SERVICECATEGORIES_CHANGE_POSITION_START",
  };
};

export const changePositionSuccess = (data) => {
  return {
    type: "SERVICECATEGORIES_CHANGE_POSITION_SUCCESS",
    menus: data,
  };
};

export const changePositionError = (error) => {
  return {
    type: "SERVICECATEGORIES_CHANGE_POSITION_ERROR",
    error: error,
  };
};

// DELETE CATEGORY

export const deleteServiceCategory = (categoryId, data) => {
  return function (dispatch, getState) {
    dispatch(deleteServiceCategoryStart());

    axios
      .delete(`service-categories/${categoryId}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(deleteServiceCategorySuccess(resultCategory));
        dispatch(loadServiceCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(deleteServiceCategoryError(resultError));
      });
  };
};

export const deleteServiceCategoryStart = () => {
  return {
    type: "DELETE_SERVICE_CATEGORY_START",
  };
};

export const deleteServiceCategorySuccess = (result) => {
  return {
    type: "DELETE_SERVICE_CATEGORY_SUCCESS",
    dlService: result,
  };
};

export const deleteServiceCategoryError = (error) => {
  return {
    type: "DELETE_SERVICE_CATEGORY_ERROR",
    error,
  };
};

// SAVE CATEGORY

export const saveServiceCategory = (category) => {
  return function (dispatch, getState) {
    dispatch(saveServiceCategoryStart());
    let data = {
      name: category.name,
      status: category.status,
    };

    if (category.parentId !== null) {
      data = {
        name: category.name,
        parentId: category.parentId,
      };
    }

    data.language = category.language;
    data.status = category.status;

    axios
      .post(`service-categories`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(saveServiceCategorySuccess(resultCategory));
        dispatch(loadServiceCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(saveServiceCategoryError(resultError));
      });
  };
};

export const saveServiceCategoryStart = () => {
  return {
    type: "CREATE_SERVICE_CATEGORY_START",
  };
};

export const saveServiceCategorySuccess = (resultCategory) => {
  return {
    type: "CREATE_SERVICE_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const saveServiceCategoryError = (error) => {
  return {
    type: "CREATE_SERVICE_CATEGORY_ERROR",
    error: error,
  };
};

// UPDATE CATEGORY

export const updateServiceCategory = (category, id) => {
  return function (dispatch) {
    dispatch(updateServiceCategoryStart());
    const data = {
      name: category.name,
    };

    axios
      .put(`service-categories/${id}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(updateServiceCategorySuccess(resultCategory));
        dispatch(loadServiceCategories());
        dispatch(loadServiceCategory(id));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(updateServiceCategoryError(resultError));
      });
  };
};

export const updateServiceCategoryStart = () => {
  return {
    type: "UPDATE_SERVICE_CATEGORY_START",
  };
};

export const updateServiceCategorySuccess = (resultCategory) => {
  return {
    type: "UPDATE_SERVICE_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const updateServiceCategoryError = (error) => {
  return {
    type: "UPDATE_SERVICE_CATEGORY_ERROR",
    error: error,
  };
};
