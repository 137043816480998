import axios from "../../axios-base";

export const clear = () => {
  return function (dispatch, getState) {
    dispatch(clearStart);
    dispatch(loadCourseCategories);
  };
};

const errorMessage = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clearStart = () => {
  return {
    type: "CLEAR_COURSECATEGORIES",
  };
};

export const loadCourseCategories = () => {
  return function (dispatch, getState) {
    dispatch(loadCourseCategoriesStart());
    axios
      .get("course-categories")
      .then((response) => {
        const result = response.data.data;
        dispatch(loadCourseCategoriesSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadCourseCategoriesError(resultError));
      });
  };
};
export const loadCourseCategoriesStart = () => {
  return {
    type: "LOAD_COURSE_CATEGORIES_START",
  };
};

export const loadCourseCategoriesSuccess = (result) => {
  return {
    type: "LOAD_COURSE_CATEGORIES_SUCCESS",
    categories: result,
  };
};

export const loadCourseCategoriesError = (error) => {
  return {
    type: "LOAD_COURSE_CATEGORIES_ERROR",
    error,
  };
};

// SINGLE CATEGORY

export const loadCourseCategory = (courseCategoryId) => {
  return function (dispatch, getState) {
    dispatch(loadCourseCategoryStart());
    axios
      .get(`course-categories/${courseCategoryId}`)
      .then((response) => {
        const loadedCourseCategory = response.data.data;
        dispatch(loadCourseCategorySuccess(loadedCourseCategory));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadCourseCategoryError(resultError));
      });
  };
};

export const loadCourseCategoryStart = () => {
  return {
    type: "LOAD_COURSE_CATEGORY_START",
  };
};

export const loadCourseCategorySuccess = (result) => {
  return {
    type: "LOAD_COURSE_CATEGORY_SUCCESS",
    category: result,
  };
};

export const loadCourseCategoryError = (error) => {
  return {
    type: "LOAD_COURSE_CATEGORY_ERROR",
    error,
  };
};

// Change positions
export const changePosition = (data) => {
  return function (dispatch) {
    dispatch(changePositionStart());

    axios
      .post("course-categories/change", data)
      .then((response) => {
        const result = response.data.data;
        dispatch(changePositionSuccess(result));
        dispatch(loadCourseCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(changePositionError(resultError));
      });
  };
};

export const changePositionStart = (result) => {
  return {
    type: "COURSECATEGORIES_CHANGE_POSITION_START",
  };
};

export const changePositionSuccess = (data) => {
  return {
    type: "COURSECATEGORIES_CHANGE_POSITION_SUCCESS",
    menus: data,
  };
};

export const changePositionError = (error) => {
  return {
    type: "COURSECATEGORIES_CHANGE_POSITION_ERROR",
    error: error,
  };
};

// DELETE CATEGORY

export const deleteCourseCategory = (categoryId, data) => {
  return function (dispatch, getState) {
    dispatch(deleteCourseCategoryStart());

    axios
      .delete(`course-categories/${categoryId}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(deleteCourseCategorySuccess(resultCategory));
        dispatch(loadCourseCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(deleteCourseCategoryError(resultError));
      });
  };
};

export const deleteCourseCategoryStart = () => {
  return {
    type: "DELETE_COURSE_CATEGORY_START",
  };
};

export const deleteCourseCategorySuccess = (result) => {
  return {
    type: "DELETE_COURSE_CATEGORY_SUCCESS",
    dlCourse: result,
  };
};

export const deleteCourseCategoryError = (error) => {
  return {
    type: "DELETE_COURSE_CATEGORY_ERROR",
    error,
  };
};

// SAVE CATEGORY

export const saveCourseCategory = (category) => {
  return function (dispatch, getState) {
    dispatch(saveCourseCategoryStart());
    let data = {
      name: category.name,
      status: category.status,
    };

    if (category.parentId !== null) {
      data = {
        name: category.name,
        parentId: category.parentId,
      };
    }

    data.language = category.language;
    data.status = category.status;

    axios
      .post(`course-categories`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(saveCourseCategorySuccess(resultCategory));
        dispatch(loadCourseCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(saveCourseCategoryError(resultError));
      });
  };
};

export const saveCourseCategoryStart = () => {
  return {
    type: "CREATE_COURSE_CATEGORY_START",
  };
};

export const saveCourseCategorySuccess = (resultCategory) => {
  return {
    type: "CREATE_COURSE_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const saveCourseCategoryError = (error) => {
  return {
    type: "CREATE_COURSE_CATEGORY_ERROR",
    error: error,
  };
};

// UPDATE CATEGORY

export const updateCourseCategory = (category, id) => {
  return function (dispatch) {
    dispatch(updateCourseCategoryStart());
    const data = {
      name: category.name,
    };

    axios
      .put(`course-categories/${id}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(updateCourseCategorySuccess(resultCategory));
        dispatch(loadCourseCategories());
        dispatch(loadCourseCategory(id));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(updateCourseCategoryError(resultError));
      });
  };
};

export const updateCourseCategoryStart = () => {
  return {
    type: "UPDATE_COURSE_CATEGORY_START",
  };
};

export const updateCourseCategorySuccess = (resultCategory) => {
  return {
    type: "UPDATE_COURSE_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const updateCourseCategoryError = (error) => {
  return {
    type: "UPDATE_COURSE_CATEGORY_ERROR",
    error: error,
  };
};
