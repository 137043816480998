const base = {
  // siteUrl: "http://localhost:3000/",

  // baseUrl: "http://localhost:3001/",
  // cdnUrl: "http://localhost:1000/uploads/",
  // apiUrl: "http://localhost:1000/v1/api/",

  siteUrl: "https://zaya-ananda.com/",

  baseUrl: "https://admin.zaya-ananda.com/",
  cdnUrl: "https://zaya-ananda.com/uploads/",
  apiUrl: "https://admin.zaya-ananda.com/api/",
};

export default base;
